const { REACT_APP_ENGYN_FORMS_MICRO, REACT_APP_ENGYN_CORE_MICRO } = window.env

const remoteUrls = [REACT_APP_ENGYN_CORE_MICRO, REACT_APP_ENGYN_FORMS_MICRO]

const bustRemoteEntriesCache = () =>
  Promise.all(
    remoteUrls.map(remoteUrl =>
      fetch(`${remoteUrl}/remoteEntry.js`, { cache: 'reload', mode: 'no-cors' })
    )
  )

bustRemoteEntriesCache().finally(() => import('./bootstrap'))
